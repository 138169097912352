import React from "react";
import { Link } from "react-router-dom";
import "../styles/LandingPage.css";
import { OptimizedVideo } from "../components/Media";

// Import the video and poster files directly
import videoMp4 from "../assets/background480-compressed.mp4";
import videoWebm from "../assets/background480-compressed.webm";
import posterImage from "../assets/background-poster-compressed.jpg";

const LandingPage = () => {
  return (
    <div className="landing-page">
      <OptimizedVideo
        videoSrc={{ mp4: videoMp4, webm: videoWebm }}
        posterSrc={posterImage}
        className="background-video"
        width="1920"
        height="1080"
        fallbackText="Your browser does not support video playback. Please try a different browser."
      />

      <div className="hero">
        <h1>Modern Money Methods</h1>
        <p>Unlock financial freedom with the right knowledge and tools.</p>
        <Link to="/introduction" className="cta-button">
          Get Started
        </Link>
      </div>
    </div>
  );
};

export default LandingPage;