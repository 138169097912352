import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

// Reusable component for images with progressive loading and responsive support
export const OptimizedImage = ({
  lowResSrc,
  highResSrc,
  srcSet,
  sizes,
  alt,
  className,
  width,
  height,
}) => (
  <LazyLoadImage
    src={highResSrc}
    placeholderSrc={lowResSrc}
    srcSet={srcSet}
    sizes={sizes}
    effect="blur"
    alt={alt}
    className={className}
    width={width}
    height={height}
    style={{ objectFit: "cover", width: "100%", height: "auto" }}
  />
);

// Reusable component for videos with lazy loading and accessibility
export const OptimizedVideo = ({
  videoSrc,
  posterSrc,
  className,
  width,
  height,
  fallbackText = "Your browser does not support video playback.",
}) => (
  <video
    className={className}
    autoPlay
    loop
    muted
    playsInline
    preload="metadata"
    loading="lazy" // Native lazy-loading
    poster={posterSrc || undefined}
    style={{ objectFit: "cover", width: "110%", height: "112%" }}
    onError={(e) => console.error("Video failed to load:", e)}
    onLoadedData={() => console.log("Video data loaded successfully")}
  >
    {typeof videoSrc === "string" ? (
      <>
        <source src={`${videoSrc}.webm`} type="video/webm" />
        <source src={`${videoSrc}.mp4`} type="video/mp4" />
      </>
    ) : (
      <>
        <source src={videoSrc.webm} type="video/webm" />
        <source src={videoSrc.mp4} type="video/mp4" />
      </>
    )}
    <p>{fallbackText}</p>
  </video>
);