import React from "react";
import { Link } from "react-router-dom"; // ✅ Added for navigation
import "../../styles/Subsections.css"; // ✅ Linked to universal stylesheet

// Import high-res WebP images from src/assets/subsectionpics
import riskManagementFrameworkHighRes from "../../assets/subsectionpics/risk-management-framework.webp";
import riskToleranceFactorsHighRes from "../../assets/subsectionpics/risk-tolerance-factors.webp";

const RiskManagement = () => {
  const handleNavigation = (path) => {
    window.scrollTo(0, 0); // Scroll to top immediately
    setTimeout(() => {
      window.location.href = path; // Navigate after scroll
    }, 0);
  };

  return (
    <div className="subsection-container">
      <h1 className="subsection-title">Risk Management: Navigating Uncertainty in Investing</h1>

      <section>
        <h2>What Risk Management Is</h2>
        <p>
          Risk management is a fundamental aspect of investing, ensuring that you are adequately compensated for the risks you take while minimizing potential losses. Because risk tolerance is highly personal, there is no universal approach—each investor must develop a framework tailored to their unique financial situation, goals, and comfort level with uncertainty.
        </p>
        <p>
          This section provides a high-level overview of risk management, but I strongly encourage further research to refine your personal strategy. More in-depth discussions on specific risk management techniques will be covered in future sections.
        </p>
        <img
          src={riskManagementFrameworkHighRes}
          alt="Risk Management Framework: Minimize Losses, Assess Risks, Ensure Rewards"
          className="section-image"
          loading="lazy"
        />
      </section>

      <section>
        <h2>The Core Principle: Risk vs. Reward</h2>
        <p>
          Investing always involves risk, but the key is ensuring that the potential rewards justify the level of risk taken. This leads to the concept of the risk/reward ratio—for every additional unit of risk, there should be a proportionate increase in potential return.
        </p>
        <p>Effective risk management involves:</p>
        <ul>
          <li>Identifying risks associated with an investment.</li>
          <li>Mitigating risks through strategies like diversification.</li>
          <li>Ensuring fair compensation for the risks you take.</li>
        </ul>
        <p>
          For example, a high-risk speculative investment should offer a potential return that makes the risk worthwhile. Conversely, a low-risk investment (such as bonds) will offer lower but more stable returns.
        </p>
      </section>

      <section>
        <h2>Types of Investment Risks</h2>
        <p>
          Every investment carries some degree of risk. Understanding these risks allows you to make informed decisions and structure your portfolio wisely.
        </p>
        <ul>
          <li>
            <strong>Market Risk:</strong> The risk of broad market downturns, such as recessions or stock market crashes. This affects nearly all investments to some extent, making diversification crucial.
          </li>
          <li>
            <strong>Credit Risk:</strong> The risk that a borrower (such as a company or government) fails to repay debt (e.g., bonds). Higher-risk bonds offer greater interest rates but come with an increased chance of default.
          </li>
          <li>
            <strong>Liquidity Risk:</strong> The risk of not being able to sell an investment quickly or at a fair price when needed. Some assets, like real estate or private equity, have low liquidity.
          </li>
          <li>
            <strong>Inflation Risk:</strong> The risk that inflation erodes purchasing power, diminishing the real value of investment returns. Cash savings and low-interest fixed-income investments are particularly vulnerable.
          </li>
          <li>
            <strong>Political & Regulatory Risk:</strong> Risks stemming from government policies, regulations, or global events (e.g., tax law changes, new financial regulations, or geopolitical conflicts) can heavily impact industries such as energy, technology, and healthcare.
          </li>
        </ul>
        <p>
          Since no investment is risk-free, proper risk management helps reduce exposure and ensure that risks are worth the potential rewards.
        </p>
      </section>

      <section>
        <h2>The Risk Curve: Balancing Risk and Return</h2>
        <p>The risk curve visualizes the relationship between risk and potential return:</p>
        <ul>
          <li>Lower-risk investments (e.g., bonds, index funds) generally offer lower returns but greater stability.</li>
          <li>Higher-risk investments (e.g., individual stocks, venture capital, cryptocurrency) have higher potential returns but come with greater uncertainty.</li>
        </ul>
        <p>Your goal is to position yourself on the curve where the return justifies the risk—not taking on excessive risk without proper reward.</p>
      </section>

      <section>
        <h2>Determining Your Risk Tolerance</h2>
        <p>Your risk tolerance is personal and depends on various factors. Answering the following questions can help you assess where you stand:</p>
        <ul>
          <li>Do you have financial dependents? (Children, spouse, aging parents)</li>
          <li>What is your age? (Younger investors can often afford more risk.)</li>
          <li>When will you need the money you are investing? (Short-term vs. long-term goals.)</li>
          <li>Would losing this money significantly impact your lifestyle?</li>
        </ul>
        <p>
          Many investors make the mistake of chasing the highest returns without considering their ability to handle losses. A devastating financial setback can be difficult to recover from, making risk assessment just as important as return potential.
        </p>
        <img
          src={riskToleranceFactorsHighRes}
          alt="Risk Tolerance Factors: Dependents, Age, Timeline, Lifestyle Impact"
          className="section-image"
          loading="lazy"
        />
      </section>

      <section>
        <h2>Risk Tolerance vs. Age</h2>
        <p>While risk tolerance is individual, age plays a significant role in determining how much risk is appropriate.</p>
        
        <h3>Younger Investors (Higher Risk Tolerance)</h3>
        <ul>
          <li>More time to recover from market downturns.</li>
          <li>Fewer financial obligations (in most cases).</li>
          <li>Higher earning potential to recoup losses over time.</li>
          <li>Can take advantage of compounding returns with long-term investments.</li>
        </ul>

        <h3>Older Investors (Lower Risk Tolerance)</h3>
        <ul>
          <li>Less time to recover from financial losses.</li>
          <li>More responsibilities (e.g., family, mortgage, retirement planning).</li>
          <li>Larger capital at risk, meaning losses are more significant in dollar terms.</li>
          <li>Greater need for stability and income-generation (e.g., dividend stocks, bonds).</li>
        </ul>
        <p>
          While younger investors can afford higher risk, it is essential to still diversify and mitigate downside exposure. Meanwhile, older investors may shift toward safer assets as they approach retirement, prioritizing capital preservation.
        </p>
      </section>

      <section>
        <h2>Do Not Invest Money You Cannot Afford to Lose</h2>
        <p>
          1 of the most overlooked aspects of investing is the importance of only using funds you can afford to commit or potentially lose. Every investment carries uncertainty—whether from market shifts, economic downturns, or unforeseen circumstances—and relying on money needed for daily living expenses can jeopardize your financial security. Rent, food, utilities, and other essentials should never hinge on the performance of your portfolio.
        </p>
        <p>
          Effective risk management starts with separating investment capital from your core financial needs. Before allocating money to investments, establish a safety net, such as an emergency fund covering 3-6 months of living expenses. This buffer ensures that you will not be forced to sell assets during a downturn, allowing your investments time to recover. The funds you invest should be discretionary—money you can leave in the market without immediate pressure to withdraw.
        </p>
        <ul>
          <li><strong>Safeguard essentials:</strong> Cover living expenses and emergencies before investing.</li>
          <li><strong>Use surplus funds:</strong> Invest only what will not disrupt your financial stability if lost.</li>
          <li><strong>Avoid forced sales:</strong> Maintain liquidity outside your portfolio to handle unexpected needs.</li>
        </ul>
        <p>
          By reserving investing for money beyond your immediate requirements, you reduce stress and enhance your ability to navigate uncertainty. This principle aligns your portfolio with your broader financial picture, ensuring that risk-taking remains a calculated choice rather than a desperate necessity.
        </p>
      </section>

      <section>
        <h2>Summary</h2>
        <p>When developing a framework for your own risk management, remember the following:</p>
        <ul>
          <li>Understand the risks you are taking before investing.</li>
          <li>Ensure the rewards justify the risk.</li>
          <li>Use strategies like diversification to reduce exposure.</li>
          <li>Assess your risk tolerance honestly.</li>
        </ul>
        <p>
          By taking a structured approach to risk, you can build a resilient portfolio that aligns with your goals while protecting yourself from unnecessary losses. The key is not avoiding risk entirely—it is managing it wisely.
        </p>
      </section>

      {/* ✅ Added Next Section Button */}
      <div className="cta-container">
        <Link
          to="/learning/advanced-investments"
          className="cta-button"
          onClick={(e) => {
            e.preventDefault(); // Prevent React Router's default navigation
            handleNavigation("/learning/advanced-investments");
          }}
        >
          Next Section
        </Link>
      </div>
    </div>
  );
};

export default RiskManagement;