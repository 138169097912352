import React, { useState } from "react";
import { Link } from "react-router-dom"; // ✅ Import Link for navigation
import "../../styles/Subsections.css"; // ✅ Linked to universal stylesheet

const CreditCards = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="subsection-container">
      <h1 className="subsection-title">Leveraging Credit Cards</h1>

      <section>
        <h2>Why Credit Cards</h2>
        <p>
          As outlined, using credit cards can earn you money back on dollars that you would already be spending—the only difference is that you are using a credit card instead of cash or a debit card.
          This cashback can take multiple forms depending on the type of card you choose. I personally leverage multiple cards for different situations and avoid using debit cards or cash unless absolutely necessary.
        </p>
        <p>
          Remember to use your credit card responsibly, as discussed in the learning section. The benefits you receive as a responsible user are paid for by the irresponsible users.
        </p>
      </section>

      <section>
        <h2>Different Types of Cards</h2>

        <h3>Credit-Building Cards</h3>
        <p><strong>Overview:</strong> Ideal for beginners, these cards typically have very low or no annual fees.</p>
        <p><strong>Benefits:</strong></p>
        <ul>
          <li>Forgivable terms that help build your credit and establish strong financial habits.</li>
          <li>More accessible than premium cards due to lower credit score requirements.</li>
        </ul>

        <h3>Travel Cards</h3>
        <p><strong>Overview:</strong> Generally considered more premium, travel cards are designed for frequent travelers.</p>
        <p><strong>Benefits:</strong></p>
        <ul>
          <li>Often include perks tied to specific airlines.</li>
          <li>Prioritize rewards geared toward travel, such as:
            <ul>
              <li>Hotel credits</li>
              <li>Flight credits</li>
              <li>Airport lounge access</li>
            </ul>
          </li>
        </ul>

        <h3>Luxury Cards</h3>
        <p><strong>Overview:</strong> These cards are usually reserved for individuals with high monthly spending.</p>
        <p><strong>Benefits:</strong></p>
        <ul>
          <li>Offer a “white glove” service with enhanced benefits.</li>
          <li>Generally require high spending levels for qualification, but if you’re invited to apply, they can be very beneficial.</li>
        </ul>

        <h3>Everyday Cards</h3>
        <p><strong>Overview:</strong> Designed for routine spending, these cards offer rewards on common purchases.</p>
        <p><strong>Benefits:</strong></p>
        <ul>
          <li>Well-suited for the average person, as they provide benefits on everyday expenditures.</li>
          <li>Reward categories typically include:
            <ul>
              <li>Gas</li>
              <li>Groceries</li>
              <li>Restaurants</li>
              <li>Entertainment</li>
            </ul>
          </li>
        </ul>
      </section>

      <section>
        <h2>Annual Fees</h2>
        <p>
          Many credit cards come with no annual fee, but some do charge a fee. You might ask, “Why pay for something I could have for free?” However, cards with annual fees often provide benefits that can quickly outweigh the fee—if you use them effectively.
        </p>

        <h3>Annual Fee vs. No Annual Fee</h3>
        <p>When deciding between a card with an annual fee and another without, consider:</p>
        <ul>
          <li><strong>Affordability:</strong> Can you handle the upfront fee?</li>
          <li><strong>Benefit Utilization:</strong> Will you use enough of the offered benefits to justify the fee?</li>
          <li><strong>Reward Alignment:</strong> Are the reward categories aligned with your spending habits?</li>
          <li><strong>Approval Requirements:</strong> Premium cards typically require higher credit scores (a quick online search can show what scores are needed).</li>
        </ul>
      </section>

      <section>
        <h2>Points: Efficient vs. Inefficient Use of Points</h2>
        <p>
          Almost all credit cards offer some form of cash back, usually in the form of points, miles, or other rewards. These rewards can be redeemed for various benefits, including:
        </p>
        <ul>
          <li>Airfare</li>
          <li>Hotel credits</li>
          <li>Gift cards</li>
          <li>Cash</li>
          <li>Statement credits</li>
        </ul>

        <h3>Efficiency of Redemption:</h3>
        <p>
          For example, 10,000 points might typically equate to about $100 USD, but this can vary.
          Redemption rates are usually less favorable for cash or statement credits.
        </p>
        <p>
          Airfare and hotel credits often offer better conversion ratios, especially during promotional periods (e.g., a 1:2 ratio, where you get twice the value).
          Similar favorable rates can occur with gift cards, particularly around the holidays.
        </p>
        <p>
          Being aware of which airlines and hotels your card favors is valuable since partner conversion rates can be significantly better.
          By understanding conversion rates and timing your redemptions, you can maximize the money you get back from your credit card usage.
        </p>
        <p>
          These benefits have, for example, paid for many flights around the world for me and provided gift cards for loved ones during the holidays.
          Depending on your spending, these benefits can add up to hundreds or even thousands of dollars.
        </p>
      </section>

      <section>
        <h2>My Personal Cards/Recommendations</h2>
        <p>
          This section outlines cards I currently use or have used and recommend. I have included more premium cards as well as entry-level cards. Each card serves a different purpose for me, as discussed. Below, I provide referral links where you can get a bonus for signing up if approved, while also supporting what I am doing here. Additionally, I provide a quick rationale as to what the card's purpose is.
        </p>

        <h1>Premium Cards</h1>

        <p>
          <strong>Capital One Venture X:</strong> A top-tier travel card offering substantial miles on spending, airport lounge access, and significant travel discounts for members. The annual fee is easily offset by its benefits. This is a staple for me.
          <br />
          <a href="https://i.capitalone.com/GbGjYSi2G" target="_blank" rel="noopener noreferrer">Referral Link: Capital One Venture X</a>
        </p>

        <p>
          <strong>Capital One Venture Rewards:</strong> A mid-tier travel card offering moderate miles on spending across specific categories, TSA Pre-Check/Global Entry Credit, and travel insurance with stipulations. This card is obviously a step down from the Venture X but serves as a solid middle ground for a moderate traveler looking for perks while having a lower annual fee.
          <br />
          <a href="https://i.capitalone.com/J2GKZRgau" target="_blank" rel="noopener noreferrer">Referral Link: Capital One Venture Rewards</a>
        </p>

        <p>
          <strong>Amex Delta Gold:</strong> Ideal for domestic travel—Delta is my preferred airline when possible. This card includes free checked bags, stay credits, modest mileage multiples, and generous welcome bonuses. Amex also provides unmatched customer service.
          <br />
          <a href="https://americanexpress.com/en-us/referral/delta-skymiles-gold-american-express-card?ref=PHILIM6Ptb&xl=cp01" target="_blank" rel="noopener noreferrer">Referral Link: Amex Delta Gold</a>
        </p>

        <p>
          <strong>Amex Gold Card:</strong> I had this card for years—great for everyday spending. I canceled it while traveling since Amex is not as widely accepted internationally. In the U.S., it offers strong perks, including high rewards on groceries and dining. As always, Amex customer service is top-notch.
          <br />
        </p>

        <h1>Entry-Level Cards</h1>

        <p>
          <strong>Discover It:</strong> My first credit card—an easy choice for beginners. It outshines most free cards with 5% cashback on rotating categories and a first-year cashback match, making it a great entry-level option.
          <br />
          <a href="https://refer.discover.com/philip.moran1!27a50e6cdf!a" target="_blank" rel="noopener noreferrer">Referral Link: Discover It</a>
        </p>

        <p>
          <strong>Capital One Savor One:</strong> A solid entry-level card that can lead to premium options in Capital One’s lineup. Benefits include 5% cashback on hotels (booked through their portal, though I don’t recommend it) and 3% cashback on dining, entertainment, and groceries.
          <br />
          <a href="https://i.capitalone.com/J9fwpXHms" target="_blank" rel="noopener noreferrer">Referral Link: Capital One Savor One</a>
        </p>

        <p>
          <strong>Apple Card:</strong> For those who want a sleek metal card. Perks are modest but include 3% cashback at select merchants and 2% when using Apple Pay, which can be useful.
          <br />
          <a href="https://apple.co/referdailycash" target="_blank" rel="noopener noreferrer">Referral Link: Apple Card</a>
        </p>
      </section>

      <section>
        <h2>Summary</h2>
        <p>
          This section provides a brief overview of the different types of credit cards, their potential benefits, and strategies for leveraging them effectively.
          There's much more to explore about credit cards, so I encourage you to ask questions and research to determine what works best for your specific financial situation.
        </p>
      </section>

      {/* ✅ Add Application Home Button */}
      <div className="cta-container">
        <Link to="/practical-applications" className="cta-button">Application Home</Link>
      </div>

      {/* ✅ Footer with Brief Disclosure and Link */}
      <footer className="footer">
        <p className="footer-text">
          Affiliate Disclosure: I may earn a commission from referral links at no cost to you.{" "}
          <span className="footer-link" onClick={() => setIsModalOpen(true)}>
            Read full disclosure
          </span>.
        </p>
      </footer>

      {/* ✅ Affiliate Disclosure Modal */}
      {isModalOpen && (
        <div className="modal-overlay" onClick={() => setIsModalOpen(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h2>Affiliate Disclosure</h2>
            <p>
              Some of the links provided in this section, such as those for credit card recommendations, are referral links. If you use these links to sign up for a product or service, I may earn a commission or bonus (e.g., cash, points, or other rewards) at no additional cost to you. This helps support the free content on <strong>Modern Money Methods</strong> and my mission to promote financial literacy.
            </p>
            <p>
              Please note that I only recommend products I personally use or believe in, based on my experience and research. However, these are not endorsements or guarantees of suitability for your specific situation. Your decision to use these links is entirely at your own risk, and I encourage you to review all terms and conditions with the provider and consult a financial professional before taking action.
            </p>
            <p>
              Thank you for supporting this site through these links—it keeps this resource free for everyone!
            </p>
            <button className="close-modal" onClick={() => setIsModalOpen(false)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreditCards;