import React, { useEffect, useState } from "react";
import { FaInstagram, FaTwitter } from "react-icons/fa";
import "../styles/Socials.css";

const highResImage = "/assets/minimal-city.webp"; // ✅ High-resolution image

const Socials = () => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    console.log(`Preloading image: ${highResImage}`);
    const img = new Image();
    img.src = highResImage;
    img.onload = () => {
      console.log(`Image loaded: ${highResImage}`);
      setImageLoaded(true);
    };
  }, []);

  return (
    <div className={`socials-page ${imageLoaded ? "loaded" : ""}`}>
      <div className="socials-container">
        <h1 className="section-title">Socials</h1>
        <p className="socials-intro">
          Stay connected and up to date! Follow along for valuable insights, discussions, and 
          exclusive financial literacy content.
        </p>

        {/* Social Media Links */}
        <div className="social-links">
          <a
            href="https://www.instagram.com/philip_moran1212/"
            target="_blank"
            rel="noopener noreferrer"
            className="social-card instagram"
          >
            <FaInstagram className="social-icon" />
            <span>Instagram</span>
          </a>

          <a
            href="https://x.com/mmmethod12?s=11"
            target="_blank"
            rel="noopener noreferrer"
            className="social-card twitter"
          >
            <FaTwitter className="social-icon" />
            <span>X (Twitter)</span>
          </a>
        </div>

        {/* Call-to-Action */}
        <div className="cta-box">
          <h2>Connect with me!</h2>
          <p>Join the conversation, stay inspired, and take control of your financial future.</p>
        </div>
      </div>
    </div>
  );
};

export default Socials;
