import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../styles/Introduction.css"; // ✅ Importing CSS for styling

const Introduction = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="introduction-container">
      <h1 className="intro-title">Introduction to the Content & Me</h1>

      <div className="intro-content">
        <section className="intro-section">
          <h2>My Background</h2>
          <p>
            I previously worked in investment banking and wealth management before realizing I was done making money for someone else. At 24, I chose to take control of my income, gain autonomy, and begin to work towards financial freedom. Instead of taking the corporate path, I am pursuing entrepreneurship—and now, I work remotely, traveling the world, experiencing new cultures, and seeing incredible destinations.
          </p>
          <p>
            This lifestyle is possible because of what I was taught about financial literacy by my mentors, my work, and my father. The principles I share here are the same principels that fund my life and move me closer to early retirement. I am not here to beg you to change or blindly take my word for it. I am simply sharing what I have learned because I see how many people want a different way of living. If that is you, then why not try something different.
          </p>
        </section>

        <section className="intro-section">
          <h2>What This Content Is, How It Will Help You, and Why I Created It</h2>
          <p>
            This content will teach you financial literacy—giving you the knowledge and tools to stop being a slave to money, your boss, or a job you resent. Financial literacy allows you to take back your freedom and design the life you truly want, whatever that may be.
          </p>
          <p>
            Use this content as a springboard to make real changes. If you are not happy with where you are, this is your opportunity to start moving in the right direction.
          </p>
          <p>
            Society as a whole fails to teach or prioritize basic financial literacy. Therefore making yourself financially literate is your own responsibility. Most of the information people hear about financial literacy is either outdated or comes from those who do not actually have any financial literacy themselves. Here, I share what I have learned—both from my own experience and from others—so you can improve your life and work toward what you really want and deserve.
          </p>

          <div className="key-takeaways">
            <h3>Being financially literate allows you to:</h3>
            <ul>
              <li>✅ Make more money</li>
              <li>✅ Lower expenses</li>
              <li>✅ Buy back your time</li>
            </ul>
          </div>

          <p>
            That last point is critical. Money is a tool. It gives you choices. It lets you say no. It creates freedom.
          </p>
          <p>
            Most people trade their time for money, just as we were taught in school and encouraged by society. There is nothing inherently wrong with that, but is it what you truly want? If not, educating yourself can change your path.
          </p>
          <p>
            Everyone has 24 hours in a day. Time is the great equalizer. Yet, the lifestyles people live are drastically different. Why? Because of the decisions they make. You do not have to trade your time for money forever. By shifting how you think about money, you can take back control.
          </p>
          <p>
            I created this because I truly believe a more financially literate world is a better world. Too many people live just to work, and I believe the lack of financial education is responsible for many personal and societal struggles. These concepts should not be secrets. They should be available to anyone willing to learn, no matter their background.
          </p>
          <p>
            If you are happy where you are, great—I am not here to convince you otherwise, in fact I applaud you. But if you know you want more, and you are willing to learn, keep reading.
          </p>
        </section>
      </div>

      {/* ✅ Standalone Centered CTA Button */}
      <div className="cta-container">
        <Link to="/learning" className="cta-button">Start Learning</Link>
      </div>

      {/* ✅ Footer with Disclaimer */}
      <footer className="footer">
        <p className="footer-text">
          Disclaimer: This website is for educational purposes only and does not provide financial advice. 
          <span className="footer-link" onClick={() => setIsModalOpen(true)}> Read full disclaimer</span>.
        </p>
      </footer>

      {/* ✅ Disclaimer Modal */}
      {isModalOpen && (
        <div className="modal-overlay" onClick={() => setIsModalOpen(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h2>Disclaimer</h2>
            <p>
              The information provided on <strong>Modern Money Methods</strong> is for educational and informational purposes only. Nothing on this Website should be construed as financial, investment, legal, or tax advice. The content is designed to help users improve their financial literacy, but it does not replace professional advice from a licensed financial professional.
            </p>
            <p>
              <strong>No Financial Advice:</strong> Modern Money Methods does not provide personalized financial recommendations. Any financial decisions you make based on the content of this Website are done at your own risk. Always consult with a certified financial advisor, accountant, or attorney before making financial decisions.
            </p>
            <p>
              <strong>No Liability:</strong> Modern Money Methods and its creators are not responsible for any financial losses, decisions, or actions taken based on the content of this Website. By using this Website, you acknowledge that you assume full responsibility for your own financial decisions.
            </p>
            <button className="close-modal" onClick={() => setIsModalOpen(false)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Introduction;
