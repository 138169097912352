import React, { useState } from "react";
import { Link } from "react-router-dom"; // ✅ Added for navigation
import supabase from "../../supabaseConfig";
import "../../styles/Subsections.css";

const TopicIdeaSubmission = () => {
  const [topicIdea, setTopicIdea] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitTopicIdea = async () => {
    if (!topicIdea.trim()) {
      alert("Please enter a topic idea.");
      return;
    }

    try {
      setIsSubmitting(true);

      const { error } = await supabase.from("topic_ideas").insert([
        {
          topic_idea: topicIdea,
        },
      ]);

      if (error) throw error;

      setTopicIdea("");
      alert("Topic idea submitted successfully!");
    } catch (error) {
      console.error("Error submitting topic idea:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="subsection-container">
      {/* Back Arrow in Upper Left Corner */}
      <Link to="/qa" className="back-arrow">
        <i className="fas fa-arrow-left"></i> {/* Font Awesome block-shaped arrow */}
      </Link>

      <h1 className="subsection-title">Suggest a Topic</h1>
      <p className="qa-submission-info">
        Have an idea for a future topic? Submit it here.
      </p>
      <div className="qa-form">
        <textarea
          className="qa-textarea"
          value={topicIdea}
          onChange={(e) => setTopicIdea(e.target.value)}
          placeholder="Type your topic idea here..."
          rows="6"
        />
        <button
          className={`qa-submit-button ${isSubmitting ? "disabled" : ""}`}
          onClick={submitTopicIdea}
          disabled={isSubmitting}
        >
          {isSubmitting ? "Submitting..." : "Submit Topic"}
        </button>
      </div>
    </div>
  );
};

export default TopicIdeaSubmission;