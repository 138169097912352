import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../styles/QA.css";

const qaTopics = [
  { name: "Submit a Question", path: "/qa/submit", icon: "❓" },
  { name: "View Questions", path: "/qa/view", icon: "📜" },
  { name: "Answered Questions", path: "/qa/answered", icon: "✅" },
  { name: "Suggest a Topic", path: "/qa/suggest-topic", icon: "💡" } // New topic added
];

const lowResImage = "/assets/minimal-qa-lowres.webp"; // ✅ Low-quality preview
const highResImage = "/assets/minimal-qa.webp"; // ✅ High-resolution image

const QA = () => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = highResImage;
    img.onload = () => setImageLoaded(true);
  }, []);

  return (
    <div className={`qa-page ${imageLoaded ? "loaded" : ""}`}>
      <div className="qa-container">
        <h1 className="section-title">Q&A Section</h1>
        <p className="section-description">
          Have questions? Still wondering about a specific topic? This section allows you to submit, browse, and upvote financial questions and topics. 
          These are my opinions and thoughts, not financial advice.
        </p>
        <div className="dynamic-nav">
          {qaTopics.map((topic) => (
            <Link 
              key={topic.path} 
              to={topic.path} 
              className="nav-item"
              onClick={() => window.scrollTo(0, 0)} // ✅ Ensures smooth navigation
            >
              <span className="icon">{topic.icon}</span> {topic.name}
            </Link>
          ))}
        </div>
        <p className="instruction-text">Click a topic to explore.</p>
      </div>
    </div>
  );
};

export default QA;