import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../styles/PracticalApplications.css";

const practicalTopics = [
  { name: "Credit Cards", path: "/practical-applications/credit-cards", icon: "💳" },
  { name: "Options", path: "/practical-applications/options", icon: "🔄" },
  { name: "Options Strategies", path: "/practical-applications/optionsstrat", icon: "💵" },
  { name: "Gold", path: "/practical-applications/gold", icon: "🥇" },
  { name: "Debt", path: "/practical-applications/debt", icon: "🛑" },
  { name: "Research Terminal", path: "/practical-applications/researchterminal", icon: "🔬" },
  { name: "Financial Statements", path: "/practical-applications/statements", icon: "📖" }
];


const lowResImage = "/assets/minimal-cc-lowres.webp"; // ✅ Low-quality preview
const highResImage = "/assets/minimal-cc.webp"; // ✅ High-resolution image

const PracticalApplications = () => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = highResImage;
    img.onload = () => setImageLoaded(true);
  }, []);

  return (
    <div className={`practical-page ${imageLoaded ? "loaded" : ""}`}>
      <div className="practical-container">
        <h1 className="section-title">Practical Applications</h1>
        <p className="section-description">
          Learning about money is only useful if you know how to apply it.
          This section focuses on real-world financial tools and strategies to help you make smarter financial moves.
        </p>
        <div className="dynamic-nav">
          {practicalTopics.map((topic) => (
            <Link 
              key={topic.path} 
              to={topic.path} 
              className="nav-item"
              onClick={() => window.scrollTo(0, 0)} // ✅ Ensures smooth navigation
            >
              <span className="icon">{topic.icon}</span> {topic.name}
            </Link>
          ))}
        </div>
        <p className="instruction-text">Click a topic to explore.</p>
      </div>
    </div>
  );
};

export default PracticalApplications;
