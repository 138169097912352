import React from "react";
import { Link } from "react-router-dom"; // ✅ Import Link for navigation
import "../../styles/Subsections.css"; // ✅ Linked to universal stylesheet

const Research = () => {
  return (
    <div className="subsection-container">
      <h1 className="subsection-title">Conducting Your Own Research</h1>

      <section>
        <h2>Why Research Matters</h2>
        <p>
          Conducting your own stock research is an essential part of becoming a knowledgeable 
          investor. While there are many tools available, industry-standard platforms like 
          Bloomberg Terminal can cost upwards of $30,000 per year, making them inaccessible 
          for most individuals.
        </p>
        <p>
          Fortunately, there is a free alternative—<strong>OpenBB Terminal</strong>. Designed with 
          individual investors in mind, OpenBB provides many of the same powerful research 
          capabilities without the high price tag. I use it regularly for my own research.
        </p>
      </section> 

      <section>
        <h2>Getting Started with OpenBB</h2>
        <h3>Installation</h3>
        <p>
          Setting up OpenBB is straightforward:
        </p>
        <ul>
          <li>Visit the OpenBB website: <a href="https://openbb.co" target="_blank" rel="noopener noreferrer">OpenBB</a>.</li>
          <li>Click "Start for Free" and create your account.</li>
          <li>The web version works well, but I prefer the downloaded version.</li>
          <li>If you choose to download it, use a browser like Chrome (Safari may cause issues).</li>
          <li>When opening OpenBB in Chrome, click the computer icon in the search bar to start the download.</li>
          <li>Once installed, you will see the OpenBB Terminal icon in your apps. It runs through Chrome.</li>
        </ul>
      </section>

      <section>
        <h2>How to Use OpenBB for Research</h2>
        <h3>Custom Dashboards</h3>
        <p>
          OpenBB allows users to set up personalized dashboards to track investments efficiently. 
          I use this feature to monitor my largest positions. 
          My dashboard includes:
        </p>
        <ul>
          <li>Real-time stock prices</li>
          <li>Market cap and volume trends</li>
          <li>Performance tracking over various timeframes</li>
        </ul>

        <h3>Comparing Financial Metrics</h3>
        <p>
          OpenBB makes it easy to compare companies across key financial metrics. Previously, I would 
          build my own comparison tables manually—now, the process is streamlined and automated.
        </p>

        <h3>Equity Dashboard</h3>
        <p>
          The Equity Dashboard template consolidates information that I previously had to pull from 
          multiple sources like Yahoo Finance. Key insights include:
        </p>
        <ul>
          <li>Company write-ups and fundamental data</li>
          <li>Detailed financials with historical comparisons</li>
          <li>Stock performance charts and valuation metrics</li>
        </ul>

        <h3>Co-Pilot Feature</h3>
        <p>
          OpenBB’s Co-Pilot feature helps analyze documents efficiently. To use it:
        </p>
        <ul>
          <li>Go to the "Data Connectors" tab.</li>
          <li>Upload documents such as 8K and 10Q filings.</li>
          <li>Click "Add Data" to generate widgets that summarize the key points.</li>
          <li>Use the Co-Pilot integration to quickly extract insights.</li>
        </ul>
      </section>

      <section>
        <h2>Key Research Insights</h2>
        <p>
          OpenBB Terminal enables quick access to valuable investment data. Some useful features include:
        </p>
        <ul>
          <li><strong>Insider Trading:</strong> Monitor buying and selling activity by executives.</li>
          <li><strong>Earnings History:</strong> Compare past earnings estimates and actual results.</li>
          <li><strong>Company Filings:</strong> Review financial reports directly within the terminal.</li>
          <li><strong>Analyst Price Targets:</strong> View projections from multiple financial firms.</li>
        </ul>
      </section>

      <section>
        <h2>Summary</h2>
        <p>
          OpenBB is a powerful tool for investors looking to enhance their research capabilities. 
          Whether you are tracking investments, comparing financials, or analyzing filings, it 
          streamlines the process and consolidates valuable data a single place. 
        </p>
        <p>
          By incorporating OpenBB into your research workflow, you can develop a more informed 
          investment strategy and take control of your financial literacy journey.
        </p>
      </section>

      {/* ✅ Disclaimer Section Styled Like the Example */}
      <section className="disclaimer-section">
        <div className="disclaimer-container">
          <h2 className="disclaimer-title">OpenBB Terminal</h2>
          <div className="disclaimer-button-container">
            <a
              href="https://openbb.co"
              target="_blank"
              rel="noopener noreferrer"
              className="disclaimer-button"
            >
              Open OpenBB
            </a>
          </div>
          <div className="disclaimer-text-container">
            <p className="disclaimer-text">
              <em>
                Source: <a href="https://openbb.co" target="_blank" rel="noopener noreferrer">OpenBB</a>. 
                This tool is provided by a third party. We are not affiliated with or responsible for its accuracy.
              </em>
            </p>
          </div>
        </div>
      </section>

      {/* ✅ Add Application Home Button */}
      <div className="cta-container">
        <Link to="/practical-applications" className="cta-button">Application Home</Link>
      </div>

    </div>
  );
};

export default Research;
