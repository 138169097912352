import React from "react";
import { Link } from "react-router-dom"; // ✅ Import Link for navigation
import "../../styles/Subsections.css"; // ✅ Linked to universal stylesheet

const OptionsSelling = () => {
  return (
    <div className="subsection-container">
      <h1 className="subsection-title">Options Basics: Selling Strategies</h1>

      <section>
      <h2>Options Strategies</h2>
        <p>
          Selling options builds on the concepts of calls, puts, and premiums outlined earlier. While buying options lets you speculate on price movements, selling them focuses on generating income. This section dives into 2 key strategies—covered calls and cash-secured puts—explaining how they work, their risks, and how market conditions affect them. These tie directly into the tools from "Options Trading," like implied volatility and The Greeks, to help manage trades effectively.
        </p>
      </section>

      <section>
        <h2>What Selling Options Involves</h2>
        <p>
          Selling an option means you collect a premium upfront—the price the buyer pays for the contract. In return, you take on an obligation: to sell shares if you sell a call, or to buy shares if you sell a put. Unlike buying options, where your risk is limited to the premium, selling shifts the risk to potential stock price movements. It is a way to turn the mechanics of options into steady income, but it requires understanding the underlying stock and market trends.
        </p>
      </section>

      <section>
        <h2>Covered Calls: Generating Income from Ownership</h2>
        <p>
          A covered call starts with owning 100 shares of a stock. You sell a call option against those shares, collecting a premium immediately. If the stock price stays below the strike price by the expiration date, the option expires worthless—you keep the premium and your shares. If the stock rises above the strike, the buyer exercises the option, and you sell your shares at the strike price, earning the premium plus any gain from your original purchase price to the strike.
        </p>
        <p><strong>Example:</strong></p>
        <ul>
          <li>A stock trades at $100. You own 100 shares and sell a call with a $110 strike price for a $2 premium ($200 total).</li>
          <li>Stock stays at $105 by expiration: The option expires, you keep the $200 and your shares.</li>
          <li>Stock rises to $120: Your shares are sold at $110. You earn $1000 ($110 - $100 x 100) plus the $200 premium, totaling $1200.</li>
        </ul>
      </section>

      <section>
        <h2>Risks of Covered Calls</h2>
        <p>
          The primary risk is capped upside. If the stock surges past the strike—say to $130—you still sell at $110, missing out on the extra $20 per share. Your maximum gain is the premium plus the difference between your purchase price and the strike. This strategy works best when the stock rises gradually or stays flat. High implied volatility (IV) can increase premiums but also signals larger price swings, raising the chance of exceeding the strike. Theta helps here—time decay reduces the option’s value as expiration nears, favoring the seller if the stock stays below the strike.
        </p>
      </section>

      <section>
        <h2>Cash-Secured Puts: Income or Discounted Buying</h2>
        <p>
          A cash-secured put involves selling a put option and reserving cash to buy 100 shares if the stock falls to the strike price. You collect a premium upfront. If the stock price remains above the strike by expiration, the option expires worthless, and you keep the premium and cash. If the stock drops below the strike, you buy the shares at that price, with the premium lowering your effective cost.
        </p>
        <p><strong>Example:</strong></p>
        <ul>
          <li>A stock trades at $100. You sell a put with a $90 strike for a $1 premium ($100 total), securing $9000 in cash.</li>
          <li>Stock stays at $95: The option expires, you keep the $100.</li>
          <li>Stock falls to $80: You buy 100 shares at $90 ($9000), but the $1 premium reduces your cost to $89 per share ($8900 total).</li>
        </ul>
      </section>

      <section>
        <h2>Risks of Cash-Secured Puts</h2>
        <p>
          The risk is a sharp decline. If the stock drops to $70, you buy at $90 (net $89 after the $100 premium), and your shares are worth $7000—a $1900 loss unless the price recovers. High IV can boost premiums but also warns of bigger drops, increasing assignment risk. Theta works in your favor—time decay erodes the put’s value if the stock stays above the strike. This strategy suits stocks you are willing to own, acting like a limit order with a bonus premium.
        </p>
      </section>

      <section>
        <h2>Market Conditions and Selling Strategies</h2>
        <p>
          Market direction and volatility affect these strategies, tying into concepts like IV from "Options Trading":
        </p>
        <ul>
          <li><strong>Bullish Markets:</strong> Covered calls shine with slow rises—premiums add income, and shares may sell profitably. High IV before an earnings report can inflate premiums, but an IV crush post-event might cap gains if the stock spikes.</li>
          <li><strong>Bearish Markets:</strong> Cash-secured puts work with gradual declines—you keep premiums or buy at a discount. Sharp drops, especially with rising IV, increase the chance of assignment at a loss.</li>
          <li><strong>Sideways Markets:</strong> Both strategies excel—low IV keeps price swings minimal, letting premiums stack up without triggering exercises.</li>
        </ul>
        <p>
          Delta also plays a role: a lower delta (e.g., 0.20) for an out-of-the-money (OTM) call or put means less chance of assignment, ideal for income-focused selling. Sudden price jumps or drops disrupt these strategies, making stable conditions key.
        </p>
      </section>

      <section>
        <h2>Stock Selection for Selling</h2>
        <p>
          Stock choice ties to risk management. For covered calls, pick stocks you are fine selling at the strike or holding if the option expires—strong companies with predictable trends reduce regret. For cash-secured puts, select stocks you want to own at the strike price if assigned. This aligns with the intrinsic value concept from "Options Trading"—ITM options are more likely to be exercised, so OTM strikes offer flexibility. Vega matters too—high volatility stocks yield bigger premiums but carry more risk.
        </p>
      </section>

      <section>
        <h2>Steps to Start Selling Options</h2>
        <p>
          To apply these strategies:
        </p>
        <ul>
          <li><strong>Know the Basics:</strong> Covered calls require 100 shares per contract; cash-secured puts need cash for strike price x 100.</li>
          <li><strong>Set Strikes:</strong> For calls, choose 5-10% above the current price; for puts, 5-10% below—OTM reduces assignment odds.</li>
          <li><strong>Check IV:</strong> High IV means higher premiums but more movement risk; low IV suits steady income.</li>
          <li><strong>Use Greeks:</strong> Aim for low delta (0.10-0.30) and positive theta to favor expiration over exercise.</li>
          <li><strong>Test Small:</strong> Sell 1 contract to learn the mechanics.</li>
          <li><strong>Watch Trends:</strong> Avoid high-volatility periods unless you accept the risks.</li>
        </ul>
      </section>

      <section>
        <h2>Summary</h2>
        <p>
          Selling covered calls and cash-secured puts extends the options framework from "Options Trading" into income generation. Covered calls leverage owned shares for premiums, thriving in stable or bullish markets, though upside is capped. Cash-secured puts offer premiums or discounted stock purchases, fitting slow bearish or flat trends, but falter in crashes. Both rely on premiums, IV, and The Greeks—delta for movement, theta for decay—to balance risk and reward. With solid stock picks and timing, these strategies turn options into a tool for consistent financial gains.
        </p>
      </section>

      {/* ✅ Add Application Home Button */}
      <div className="cta-container">
        <Link to="/practical-applications" className="cta-button">Application Home</Link>
      </div>

    </div>
  );
};

export default OptionsSelling;



