import React from "react";
import { Link } from "react-router-dom"; // ✅ Added for navigation
import "../../styles/Subsections.css"; // ✅ Linked to universal stylesheet

// Import only high-res WebP images from src/assets/subsectionpics
import deductionsOverviewHighRes from "../../assets/subsectionpics/deductions-overview.webp";

const ManagingFinances = () => {
  const handleNavigation = (path) => {
    window.scrollTo(0, 0); // Scroll to top immediately
    setTimeout(() => {
      window.location.href = path; // Navigate after scroll
    }, 0);
  };

  return (
    <div className="subsection-container">
      <h1 className="subsection-title">Managing Your Finances</h1>

      <section>
        <h2>Understanding Your Paycheck</h2>
        <p>
          Many people assume they know what they earn but rarely check their paychecks for accuracy. Mistakes happen—miscalculations in deductions, incorrect tax withholdings, or employer errors could cost you money. Taking a few minutes to verify your paycheck ensures you are getting what you have earned.
        </p>
        <p>Your paycheck consists of 2 key amounts:</p>
        <ul>
          <li><strong>Gross Pay</strong> – Your earnings before any deductions.</li>
          <li><strong>Net Pay</strong> – Your take-home pay after deductions (taxes, benefits, etc.).</li>
        </ul>
        <p>
          For example, if your gross pay is $5,000, but after taxes and deductions, your net pay is $3,800, that means $1,200 was withheld for various obligations. Understanding this distinction is crucial for budgeting accurately.
        </p>
      </section>

      <section>
        <h2>Understanding Deductions (Tax Basics: Federal & State)</h2>
        <p>
          Now you are probably wondering, “What exactly are these deductions?”
        </p>
        <p>
          I encourage you to review your paycheck line by line—not just to see what is deducted, but to understand where your money is going. If you are unsure about any deductions, HR or online resources can help.
        </p>
        <p>Here is a high-level breakdown:</p>
        <ul>
          <li>
            <strong>Taxes:</strong> Most employers withhold federal, state, and sometimes local taxes in advance. These are estimated amounts, meaning you may receive a refund or owe more when filing taxes.
          </li>
          <li>
            <strong>Benefits:</strong> If your employer offers benefits like health insurance or retirement plans, contributions will be deducted from your paycheck.
          </li>
          <li>
            <strong>Retirement Contributions:</strong> If you are contributing to a 401(k) or other retirement plan, this will also be a line item. (I will cover this in detail later.)
          </li>
        </ul>
        <img
          src={deductionsOverviewHighRes}
          alt="Deductions Overview: Taxes, Benefits, and Retirement Contributions"
          className="section-image"
          loading="lazy"
        />
        <p>
          While I am not a tax expert, I strongly recommend consulting a CPA if you want deeper insight into how these deductions apply to your specific situation.
        </p>
      </section>

      <section>
        <h2>Creating a Budget</h2>
        <p>
          Once you understand your income, expenses, and essential costs, it is time to build a budget. A budget serves as a financial roadmap, helping you stay on track with your long-term goals and make adjustments when needed.
        </p>

        <h3>Choosing a Budgeting Timeframe</h3>
        <p>
          You can budget weekly, biweekly, or monthly—whichever works best for you. A monthly budget is often ideal because it provides enough flexibility to make adjustments without being tedious to track.
        </p>

        <h3>Fixed vs. Variable Expenses</h3>
        <p>
          When budgeting, categorize your expenses into 2 groups:
        </p>
        <ul>
          <li>
            <strong>Fixed Expenses:</strong> Consistent costs such as rent, car payments, or insurance.
          </li>
          <li>
            <strong>Variable Expenses:</strong> Fluctuating costs like groceries, utilities, or medical bills.
          </li>
        </ul>
        <p>
          Fixed expenses are predictable, while variable expenses require estimating. I recommend overestimating variable expenses to prevent budget shortfalls.
        </p>

        <h3>Budget Breakdown Example</h3>
        <p>Let us walk through a basic monthly budget example:</p>
        <ul>
          <li>
            <strong>Net Earnings:</strong> $4,000
          </li>
          <li>
            <strong>Fixed Expenses:</strong> $1,700 (Rent: $1,000, Car Payment: $500, Insurance: $200)
          </li>
          <li>
            <strong>Remaining After Fixed Expenses:</strong> $2,300
          </li>
          <li>
            <strong>Variable Expenses:</strong> $625 (Utilities: $125, Groceries: $400, Medical: $100)
          </li>
          <li>
            <strong>Remaining After Variable Expenses:</strong> $1,675
          </li>
          <li>
            <strong>Entertainment Budget:</strong> $375 (Dining out, social activities, etc.)
          </li>
          <li>
            <strong>Remaining After Entertainment:</strong> $1,300
          </li>
          <li>
            <strong>Emergency Fund Contribution:</strong> $100 (Cash reserve for unexpected expenses)
          </li>
          <li>
            <strong>Remaining for Savings/Investments:</strong> $1,200
          </li>
        </ul>
        <p>
          This approach ensures that essentials, enjoyment, and savings are all accounted for, keeping you financially stable while working toward long-term goals.
        </p>
      </section>

      {/* ✅ Added Next Section Button */}
      <div className="cta-container">
        <Link
          to="/learning/credit-debt"
          className="cta-button"
          onClick={(e) => {
            e.preventDefault(); // Prevent React Router's default navigation
            handleNavigation("/learning/credit-debt");
          }}
        >
          Next Section
        </Link>
      </div>
    </div>
  );
};

export default ManagingFinances;